<template>
  <section class="section container p-t-sm">
    <img class="img" src="@/assets/img/banner.png" alt="Banner" @click="redirect" />
    <div class="columns m-t-sm">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <img src="@/assets/img/certificado1.png" alt="Certificado" />
            <h2>CERTIFICADO</h2>
            <p>
              Após a conclusão de cada curso será disponibilizado na forma
              digital o certificado de conclusão com o selo da ASSBAN-DF Cursos.
            </p>
          </div>
          <footer class="card-footer">
            <router-link class="button is-text is-fullwidth" to="/cursos"
              >SAIBA MAIS</router-link
            >
          </footer>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <div class="card-content">
            <img src="@/assets/img/horario1.png" alt="HORÁRIO FLEXÍVEL" />
            <h2>HORÁRIO FLEXÍVEL</h2>
            <p>
              A ASSBAN-DF facilita seu curso, não há necessidade de agendamento,
              você pode realizar todo o curso sem sair de casa, na hora em que
              for mais conveniente para você.
            </p>
          </div>
          <footer class="card-footer">
            <router-link class="button is-text is-fullwidth" to="/cursos"
              >SAIBA MAIS</router-link
            >
          </footer>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <div class="card-content">
            <img src="@/assets/img/exercicios1.png" alt="EXERCÍCIOS" />
            <h2>EXERCÍCIOS</h2>
            <p>
              Exercícios elaborados por professor graduado e com vários níveis
              de dificuldade, em um ambiente que estimula praticar cada vez
              mais.
            </p>
          </div>
          <footer class="card-footer">
            <router-link class="button is-text is-fullwidth" to="/cursos"
              >SAIBA MAIS</router-link
            >
          </footer>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <div class="card-content">
            <img src="@/assets/img/colaboracao1.png" alt="COLABORAÇÃO" />
            <h2>COLABORAÇÃO</h2>
            <p>
              Processo de colaboração na Educação online por meio da interação e
              colaboração com os colegas e professor, tornam-se mais confiantes,
              motivados e reflexivos.
            </p>
          </div>
          <footer class="card-footer">
            <router-link class="button is-text is-fullwidth" to="/cursos"
              >SAIBA MAIS</router-link
            >
          </footer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    redirect() {
      this.$router.push({ path: "/cursos" });
    },
  },
};
</script>

<style scoped lang="scss">
.img {
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}
.card {
  height: 100%;

  .card-content {
    text-align: center;
    height: 90%;

    img {
      width: 7rem;
      height: 7rem;
    }

    h2 {
      font-weight: bold;
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }
  }
}
</style>
